@media screen and 	(max-width: 48em){

	.fondo_home_1{
		background-image: url(../img/fondo_home_movil_1.jpg)
	}

	.fondo_home_2{
		background-image: url(../img/fondo_home_movil_2.jpg)
	}

	.fondo_home_3{
		background-image: url(../img/fondo_home_movil_3.jpg)
	}


	body {
		overflow: auto;
		.view{
			min-height: auto;

			&.main-view{
				position: absolute;
				min-height: 630px;
			}

		}

		section.content-slide{
			position: static;
			transform: none;
			width: 90%;
			margin: 0 auto;
			border-color: rgba(0, 0, 0, 0.1);
			border-radius: 5px 5px 0 0;
			padding: 15px 0;
			padding-top: 0;
			min-height: 512px;

			h1{
				font-size: 0.6rem;
			}

			.content-desc{
				margin-top: 1rem;
				width: 100%;

				h2{
					font-size: 1.1rem;
				}

				p{
					font-size: 0.8rem;
					min-height: 50px;
					margin-bottom: 0.5rem;
				}
			}

			.img-slide{
				min-height: 190px;

				.bullet{
					width: 30px;
					height: 30px;
					font-size: 1.2rem;
				}

				.cuenta-gif {
					height: 3%;
				}
			}

			.content-buttons{


				&.buttons-left{
					text-align: center;

					.button{
						margin: 0 auto;
					}

				}


			}

			&.slide-portrait{
				min-height: 660px;

				.content-desc{
					margin-top: 10%;

					h2{
						width: 100%;
					}
				}
			}
		}

		section.content-cover{
			position: static;
			transform: none;
			min-height: inherit;

			& > .row{
				position: static;
			}

			.content-image{
				img{
					height: auto;
					max-width: 100%;

					position: relative;
					bottom: none;
					transform: none;
				}
			}

			.content-desc{
				position: static;
				transform: none;


				.text-cover{
					margin: 2rem 0 2rem;

					h1{
						font-size: 2rem;
					}
				}
				.icon-cover{
					margin-top: 2rem;
				}

				.button{
					margin-bottom: 5rem;
				}

				.span-label{
					margin-top: 0;
					margin-bottom: 2rem;
				}
			}


			.menu-cover{
				background: white;
				.icon-cover{

					margin: 0 auto 1rem;
				}
				.content-desc{
					width: 100%;
					min-height: 310px;
					padding-top: 2rem;
					padding-bottom: 1rem;

					.text-cover{
						width: 70%;
						margin: 0 auto;

						h2{
							/*font-size: 1.3rem;
							width: 80%;
							margin: 0 auto 2rem;*/
							font-size: 1.3rem;
							width: 100%;
							margin: 0 auto 2rem;
							padding-left: 5px;
						}

						ul{
							li{
								height: 30px;

								a{
									padding-top: 10px;
									font-size: 0.6rem;
								}

								span.number{
									height: 35px;
									width: 35px;
									font-size: 1.2rem;
								}
							}
						}
					}
				}
			}

		}

		section.content-home{
			position: static;

			.content-home-center{
				width: 90%;
				margin: 0 auto;
				position: absolute;
				z-index: 9;
				right: 50%;
				bottom: 50%;
				transform: translate(50%, 50%);

				p{
					font-size: 0.8rem;
				}

				h1{
					font-size: 3rem;
					margin-bottom: 2rem;

					span{
						font-size: 1.8rem;
					}
				}

				ul{
					li{
						width: 25%;
						margin-bottom: 0.5rem;
					}
				}

				.menu-home{
					width: 80%;
					margin-top: 1rem;

					li{
						transform: scale(0.8);
						transform-origin: center center;
						margin-bottom: 0;
					}
				}

				.button{
					width: 70%;
					margin: 0;
				}
			}
		}

		footer{

			position: static;
			height: auto;

			&.footer-cover{
				height: auto;

				img{
					position: static;
					height: auto;
					transform: none;
				}
			}

			&.footer-slide{
				height: auto;

			}


			&.footer-home{
				width: 100%;
				right: 50%;
				text-align: center;
				position: absolute;
				bottom: 1rem;

				.logo-footer{
					background: transparent;
				}

				img{
					width: 85%;
				}
			}

			.logo-footer{
				background: #253771;
				text-align: center;
				padding: 0.5rem 0;

				img{
					width: 75%;
				}
			}
		}
	}

	.arrow{
		display: none !important;
	}

	.topbar{
		padding: 1rem 0;
		h1{
			font-size: 0.89rem;
			line-height: 1;
			margin: 0;

			strong{
				display: block;
				font-size: 100%;
			}
		}

		.toggle-menu{
			position: static;
			width: 26px;
			height: 22px;

			span, &:before, &:after{
				height: 4px;
			}

			span{
				margin: 3px 0;
			}
		}
	}

	.button{
		width: 120px;
		margin: 0 0.1rem;
		font-size: 0.8rem;

		&.small-button{
			/*min-width: 110px;*/
			min-width: 200px;
	    margin-bottom: 10px !important;
		}
	}

	.toggle-menu{
		right: 2.5rem;
		top: 2rem;
	}

	.home-button{
		display: none;
	}

	.mostrar:hover + .oculto {
		display: none !important;
	}

	.overlay-menu{
		.main-menu{
			width: 300px;

			ul{

				width: 80%;

				li{
					padding: 0.2rem 0;
				}


				a{
					font-size: 0.8rem;
				}

				bottom: 46%;

				li{

					.icon{
						background-size: 625px 627px;
					}

					&:nth-child(1){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -463px);
						}
					}
					&:nth-child(2){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -32px);
						}
					}
					&:nth-child(3){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, 2px);
						}
					}
					&:nth-child(4){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -130px);
						}
					}
					&:nth-child(5){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -63px);
						}
					}
					&:nth-child(6){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -165px);
						}
					}
					&:nth-child(7){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -96px);
						}
					}
					&:nth-child(8){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -398px);
						}
					}
					&:nth-child(9){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -430px);
						}
					}
					&:nth-child(10){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -265px);
						}
					}
					&:nth-child(11){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -299px);
						}
					}
					&:nth-child(12){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -332px);
						}
					}
					&:nth-child(13){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -365px);
						}
					}
					&:nth-child(14){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -199px);
						}
					}
					&:nth-child(15){
						.icon{
							@include sprite($asset, 30px, 30px, 1px, -232px)
						}
					}
				}
			}
		}
	}

	//>Skin Canales
	.multifuncional{
		&.topbar h1 a{
			color: $multifuncional-color;
		}


		.menu-cover{

			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -480px, -12px);
				margin: 0 auto;
			}

			.content-desc{
				background-color: $multifuncional-color;
			}

		}
	}

	.kiosko{
		&.topbar h1 a{
			color: $kiosko-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -393px, -325px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $kiosko-color;
			}
		}
	}

	.cajero{
		&.topbar h1 a{
			color: $cajeros-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -394px, -221px);
				margin: 0 auto;
			}
			.content-desc{
				background-color:$cajeros-color;
			}
		}
	}

	.internet{
		&.topbar h1 a{
			color: $internet-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 60px, 49px, -390px, -120px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $internet-color;
			}
		}
	}

	.bancamovil{
		&.topbar h1 a{
			color: $bancamovil-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -395px, -13px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $bancamovil-color;
			}
		}
	}

	.servilinea{
		&.topbar h1 a{
			color: $servilinea-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -393px, -430px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $servilinea-color;
			}
		}
	}

	.tuitbdb{
		&.topbar h1 a{
			color: $tuitbdb-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -568px, -324px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $tuitbdb-color;
			}
		}
	}

	.corresponsales{
		&.topbar h1 a{
			color: $corresponsales-color;
		}



		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -570px, -221px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $corresponsales-color;
			}
		}
	}


	.avalpay{
		&.topbar h1 a{
			color: $aval-color;
		}

		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 52px, 55px, -480px, -323px);
				margin: 0 auto;
			}
			.content-desc{
				background-color:  $aval-color;
			}
		}
	}

	.avalcenter{
		&.topbar h1 a{
			color: $aval-center;
		}

		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 74px, 55px, -473px, -429px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $aval-center;
			}
		}
	}

	.bancamovilsms{
		&.topbar h1 a{
			color: $bancamovilsms-color;
		}

		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 74px, 55px, -559px, -12px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $bancamovilsms-color;
			}
		}
	}

	.mibobdb{
		&.topbar h1 a{
			color: $bobdb-color;
		}

		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 74px, 55px, -559px, -116px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $bobdb-color;
			}
		}
	}

	.pse{
		&.topbar h1 a{
			color: $pse-color;
		}

		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 74px, 55px, -469px, -116px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $pse-color;
			}
		}
	}


	.clavesegura{
		&.topbar h1 a{
			color: $clavesegura-color;
		}

		.menu-cover{
			.icon-cover{
				@include sprite($asset-mobil, 74px, 55px, -469px, -222px);
				margin: 0 auto;
			}
			.content-desc{
				background-color: $clavesegura-color;
			}
		}
	}
	.view.ng-enter,
	.view.ng-leave {
		transition: none;
	}
	.view.ng-enter {
		animation: none;
	}



	/* Corresponsales */

	.h2Corresponsal {
		font-size: 1.2rem;
    color: #80c448;
    text-align: center;
    width: 90%;
    margin: 0 auto 2rem;
    line-height: 1;
	}

	.divCenterFull{
	    position: static;
	    width: 97%;
	    transform: none;


	    .imgCorrespnsal {
	    	max-width: 70%;
	    }

	}

	.migaCorresponsal {
		margin: 1rem auto !important;
	}


	body section.content-slide.slide-portrait {
    	min-height: none;

	}

	body section.content-slide .img-slide {
    	min-height: 123px;
	}

	body section.content-slide.slide-portrait .img-slide {
	    text-align: center;
	}


	.row .row {
	    margin-top: 0px;
	}

	.noDisplay {
		display:none;
	}

	body section.content-slide h1 {
	    font-size: 18px;
	    position: static;
	    text-align: center;

	}

	body section.content-slide.slide-portrait .img-slide img:first-child {
	    width: 72%;
	    padding-bottom: 12px;
	}

	body section.content-slide .content-buttons {
	    text-align: center;
	    margin: 0rem 0 0;
	}

	.imgCorresponsal{
		/*width: 100%;
    	padding-bottom: 12px;
    	padding-top: 50%;*/
	}

	body section.content-slide.slide-portrait .img-slide img:first-child {
	    width: 91%;
	    padding-bottom: 12px;
	}




	/*slide_video*/

	body section.content-slide .img-slide .video {
	    left: 50%;
	    top: 50%;
	    width: 80%;
	    transform: translate(-50%, -53%);
	}

	body section.content-slide .img-slide .divBackgroundVideo {
	    background-position: center center;
	    width: 100%;
	    background-size: contain;
	    height: 500px;
	    position: relative;
	    max-width: 258px;
	    margin: 0 auto;
	}


	body section.content-slide.slide-portrait {
		min-height: calc(100% - 115px);
		height: auto;
	}


	body section.content-slide .img-slide &.videoImg {
	    width: 79%;
	}

	body section.content-slide .img-slide .video.videoImg {
	    width: 79% !important;
	}
	/*slide_video*/





 






















}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
	body .view{
	}

	body section.content-cover .content-image img{
	    max-width: none;
	    height: 100%;
	    width: auto;
		position: absolute;
		right: 50%;
		transform: translate(50%, 50%);
	}

	body section.content-cover .content-desc .text-cover h1{
		font-size: 2.5rem;
	}
}


@media only screen and (min-width : 650px) and (max-width : 1024px) {
	body section.content-cover .menu-cover .content-desc {
	}

	body footer .logo-footer img{
		width: 50%;
	}

	body section.content-cover .menu-cover .content-desc .text-cover ul li a {
		font-size: 12px;
		padding-top: 19px;
	}
}

@media only screen and (max-width : 650px) {

	body section.content-cover .menu-cover .content-desc .text-cover {
	    width: 88%;
	    margin: 0 auto;
	}
}
