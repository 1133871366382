@import "app.mixins";
.internet-cpm-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-cpm-paso1-zoom img {
    transform: scale3d(1.2, 1.2, 1.2);
    animation: kenburns-internet-paso1 $img-zoom;
 }
 @keyframes  kenburns-internet-paso1{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
    }
    95% {
            transform: scale3d(1.5, 1.5, 1) translate3d(11%, 8%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }



 .internet-cpm-paso2-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-cpm-paso2-zoom img {
    transform: scale3d(1.2, 1.2, 1.2);
    animation: kenburns-internet-cmp-paso2 $img-zoom;
 }

 @keyframes  kenburns-internet-cmp-paso2{
    0%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    5%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    95% {
        transform: scale3d(1.3, 1.3, 1.3) translate3d(0px, 0px, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1.3);
        opacity: 0 translate3d(0, 0, 0);
    }
 }





 .internet-ps-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }

 .internet-ps-paso1-zoom img {
    animation: kenburns-internet-pss-paso1 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }

 @keyframes  kenburns-internet-pss-paso1{
    0%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    5%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    95% {
        transform: scale3d(1.4, 1.4, 1.4) translate3d(3%, 5%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }






 .internet-ps-paso2-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-ps-paso2-zoom img {
    transform: scale3d(1.2, 1.2, 1.2);
    animation: kenburns-internet-ps-paso2 $img-zoom ;
 }
 @keyframes  kenburns-internet-ps-paso2{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1.5) translate3d(-9%, -2%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1.3);
       
    }
 }



 .internet-ps-paso3-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-ps-paso3-zoom img {
    animation: kenburns-internet-ps-paso3 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ps-paso3{
    0%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    5%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    95% {
        transform: scale3d(1.8, 1.8, 1.8) translate3d(-123px, -29px, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


 .internet-ps-paso4-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-ps-paso4-zoom img {
    animation: kenburns-internet-ps-paso4 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ps-paso4{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1.5) translate3d(-5%, -8%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1.3);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


 .internet-tec-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-tec-paso1-zoom img {
    animation: kenburns-internet-ps-paso1 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ps-paso1{
    0%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    5%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }
    95% {
        transform: scale3d(1.5, 1.5, 1.5) translate3d(-5%, 10%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }




 .internet-tec-paso2-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-tec-paso2-zoom img {
    animation: kenburns-internet-ps-paso2 $img-zoom ;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ps-paso2{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1.5) translate3d(-9%, -2%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }




 .internet-tec-paso3-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-tec-paso3-zoom img {
    animation: kenburns-internet-ps-paso3 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ps-paso3{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.8, 1.8, 1.8) translate3d(-5%, -5%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.4, 1.4, 1.4);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


.internet-tec-paso4-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-tec-paso4-zoom img {
    animation: kenburns-internet-ps-paso4 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ps-paso4{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(0%, -5%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.4, 1.4, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }








 .internet-ics-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-ics-paso1-zoom img {
    animation: kenburns-internet-ics-paso1 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ics-paso1{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.6, 1.6, 1) translate3d(7%, -7%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


.internet-ics-paso2-zoom{
     border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-ics-paso2-zoom img{
    animation: kenburns-internet-ics-paso2 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-ics-paso2{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.6, 1.6, 1) translate3d(9%, -17%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

 
 .internet-ics-paso3-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-ics-paso3-zoom img {
    animation: kenburns-internet-ics-paso3 $img-zoom;
    transform: scale3d(1.2, 1.2, 1);
 }
 @keyframes  kenburns-internet-ics-paso3{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.6, 1.6, 1) translate3d(0%, -14%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.4, 1.4, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }



 .internet-icc-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso1-zoom img{
    animation: kenburns-internet-icc-paso1 $img-zoom;
    transform: scale3d(1.8, 1.8, 1.8);
    opacity: 0 translate3d(-7%, 8%, 0px);
 }
 @keyframes  kenburns-internet-icc-paso1{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }

    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.8, 1.8, 1) translate3d(-7%, 8%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.8, 1.8, 1);
        opacity: 0 translate3d(-7%, 8%, 0px);
    }
 }


.internet-icc-paso2-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso2-zoom img {
    animation: kenburns-internet-icc-paso2 $img-zoom    ;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-icc-paso2{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(-2%, -11%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }



 .internet-icc-paso3-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso3-zoom {
    animation: kenburns-internet-icc-paso3 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-icc-paso3{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(2%, -12%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


 .internet-icc-paso4-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso4-zoom img {
    animation: kenburns-internet-icc-paso4 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-icc-paso4{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(0%, -15%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1.3);
        opacity: 0 translate3d(0, 0, 0);
    }
 }





.internet-icc-paso5-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso5-zoom img {
    animation: kenburns-internet-icc-paso5 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-icc-paso5{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(0%, -16%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


.internet-icc-paso6-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso6-zoom img{
    animation: kenburns-internet-icc-paso6 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-icc-paso6{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(0%, -14%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }




 .internet-icc-paso7-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;    
    overflow: hidden;
 }
 .internet-icc-paso7-zoom img{
    animation: kenburns-internet-icc-paso7 $img-zoom;
    transform: scale3d(1.5, 1.5, 1) translate3d(2%, -7%, 0px);
 }
 @keyframes  kenburns-internet-icc-paso7{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(2%, -7%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

.internet-icc-paso8-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso8-zoom img{
    animation: kenburns-internet-icc-paso8 $img-zoom ;
    transform: scale3d(1.1, 1.1, 1.1);
 }
 @keyframes  kenburns-internet-icc-paso8{
    0%{
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.15, 1.15, 1);
    }
    95% {
        transform: scale3d(1.2, 1.2, 1) translate3d(0%, -4%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.1, 1.1, 1.1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

 .internet-icc-paso9-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso9-zoom img {
    animation: kenburns-internet-icc-paso9 $img-zoom;
    transform: scale3d(1.1, 1.1, 1.1);
 }
 @keyframes  kenburns-internet-icc-paso9{
    0%{
        opacity: 1;
    }
    5%{
        opacity: 1;
    }
    95% {
        transform: scale3d(1.2, 1.2, 1) translate3d(-4%, -5%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.1, 1.1, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

.internet-icc-paso10-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-icc-paso10-zoom img{
    animation: kenburns-internet-icc-paso10 $img-zoom ;
    transform: scale3d(1.1, 1.1, 1.1);
 }
 @keyframes  kenburns-internet-icc-paso10{
    0%{
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1);
    }
    95% {
        transform: scale3d(1.2, 1.2, 1) translate3d(-4%, -3%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.1, 1.1, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


 .internet-extractos-paso10-zoom{
     border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-extractos-paso10-zoom img {
    animation: kenburns-internet-extractos-paso10 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-extractos-paso10{
    0%{
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(4%, 5%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


  .internet-extractos-paso11-zoom{
    overflow: hidden;

 }
 .internet-extractos-paso11-zoom img {
    animation: kenburns-internet-extractos-paso11 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-extractos-paso11{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(0%, -10%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }



  .internet-extractos-paso11-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-extractos-paso11-zoom img{
    animation: kenburns-internet-extractos-paso11 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-extractos-paso11{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.5, 1.5, 1) translate3d(-4%, -2%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

.internet-certificados-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;

 }
 .internet-certificados-paso1-zoom img{
    animation: kenburns-internet-certificados-paso1 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-certificados-paso1{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(0%, 0%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1.2);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


.internet-certificados-paso2-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-certificados-paso2-zoom img{
    animation: kenburns-internet-certificados-paso2 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-certificados-paso2{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(10%, -4%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1.3);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

 .internet-certificados-paso3-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-certificados-paso3-zoom img {
    animation: kenburns-internet-certificados-paso3 $img-zoom;
    transform: scale3d(1.2, 1.2, 1.2);
 }
 @keyframes  kenburns-internet-certificados-paso3{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1.2);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(-3%, 0%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

.internet-certificados-paso4-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-certificados-paso4-zoom img {
    animation: kenburns-internet-certificados-paso4 $img-zoom;
    transform: scale3d(1.2, 1.2, 1);
 }
 @keyframes  kenburns-internet-certificados-paso4{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);

    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(-3%, 0%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

 .internet-certificados-paso5-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-certificados-paso5-zoom img{
    transform: scale3d(1.2, 1.2, 1);    
    animation: kenburns-internet-certificados-paso5 $img-zoom;
 }
 @keyframes  kenburns-internet-certificados-paso5{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);   
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(6%, -4%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


 .internet-certificados-paso6-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-certificados-paso6-zoom img {
    transform: scale3d(1.2, 1.2, 1);
    animation: kenburns-internet-certificados-paso6 $img-zoom;
 }
 @keyframes  kenburns-internet-certificados-paso6{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(6%, -4%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;

    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }

  .internet-certificados-paso7-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-certificados-paso7-zoom img {
    transform: scale3d(1.2, 1.2, 1);
    animation: kenburns-internet-certificados-paso7 $img-zoom ;
 }
 @keyframes  kenburns-internet-certificados-paso7{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(6%, -10%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }




.internet-topes-paso1-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-topes-paso1-zoom img{
    animation: kenburns-internet-topes-paso1 $img-zoom;
    transform: scale3d(1.2, 1.2, 1);
 }
 @keyframes  kenburns-internet-topes-paso1{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(11%, -12%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


.internet-topes-paso2-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-topes-paso2-zoom img{
    animation: kenburns-internet-topes-paso2 $img-zoom ;
    transform: scale3d(1.2, 1.2, 1);
 }
 @keyframes  kenburns-internet-topes-paso2{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.6, 1.6, 1) translate3d(0%, 6%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }


 .internet-topes-paso3-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-topes-paso3-zoom img{
    animation: kenburns-internet-topes-paso3 $img-zoom  ;
    transform: scale3d(1.2, 1.2, 1);
 }
 @keyframes  kenburns-internet-topes-paso3{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(0%, -12%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);
    }
 }



.internet-topes-paso4-zoom{
    border-bottom: 5px #c1c1c1 solid;
    border-left: 21px #c1c1c1 solid;
    border-right: 22px #c1c1c1 solid;
    border-top: 5px #c1c1c1 solid;
    overflow: hidden;
 }
 .internet-topes-paso4-zoom img{
    transform: scale3d(1.1, 1.1, 1);
    animation: kenburns-internet-topes-paso4 $img-zoom;
 }
 @keyframes  kenburns-internet-topes-paso4{
    0%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    5%{
        opacity: 1;
        transform: scale3d(1.2, 1.2, 1);
    }
    95% {
        transform: scale3d(1.4, 1.4, 1) translate3d(11%, -13%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    100%{
        transform: scale3d(1.3, 1.3, 1);
        opacity: 0 translate3d(0, 0, 0);

    }
 }