@import "app.mixins";

*,h1, h2, h3, h4, h5, h6{
	font-family: $font-main-face;
}

.row{
	max-width: none;
}

.fondo_home_1{
	background-image: url(../img/fondo_home_1_d.jpg)
}

.fondo_home_2{
	background-image: url(../img/fondo_home_2_d.jpg)
}

.fondo_home_3{
	background-image: url(../img/fondo_home_3_d.jpg)
}

.drunter {
    position:relative;
    background-color:#00aaff;
}
.drueber {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:none;
    background-color:#00f;
}
.drunter:hover .drueber {
    display:block;
}
.loader{
	position: absolute;
	background: #f0f0f0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: $internet-color;
	font-weight: bold;
	z-index: 1000;

	img{
		position: absolute;
		right: 50%;
		bottom: 50%;
		transform: translate(50%,50%);
		width: 60px !important;
	}

	.percent{
		display: block;
		position: absolute;
		right: 50%;
		bottom: 50%;
		transform: translate(50%, 50%);
		font-size: 1.3rem;
	}

}

body{
	color: $body-main-color;

	.view{
		position: absolute;
		min-height: 930px;
		top: 0;
		left: 0;
	}

	section.content-home{
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;

		.mySlides {
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.slide_image{
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
		}
		.slideshow-container {
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background: no-repeat;
		}	
		.active {
			background-color: #eee;
		}	
		.fade {
    		transition: all 0.5s ease-in-out;
    	}	
		
		.content-home-center{
			width: 75%;
			position: absolute;
			right: 50%;
			bottom: 50%;
			transform: translate(50%, 55%);
			text-align: center;

			.oculto {
				padding: 20px;
				display: none;
			}
				
			.mostrar:hover + .oculto {
				display: block;
				position: absolute;
				font-size: 1rem;
				color: white;
				text-align: center;
				line-height: 1rem;
				width: 150px;
				margin-left: -25px;
				background: #e1a120;
				margin-top: 1rem;
				padding: 0.5rem;
				border-radius: 0.3rem;


				&:before{
					content: '';
					width: 0;
					height: 0;
					border-right: 10px solid transparent;
					border-top: 10px solid transparent;
					border-left: 10px solid transparent;
					border-bottom: 10px solid #e2a122;
					position: absolute;
					top: -19px;
					right: 50%;
					transform: translateX(50%);
				}
			}
			.button{
				width: 44%;
  				max-width: 200px;
				background: transparent;
				border-color: #70cddd;
				color: white;
				border-radius: 0;

				&:hover{
					background: #70cddd;
				}
			}

			h1{
				color: #70cddd;
				font-size: 5rem;
				line-height: 1;
				margin-bottom: 1rem;
				font-weight: bold;

				span{
					display: block;
					color: white;
					font-size: 2.4rem;
				}
			}
				
			p{
				color: white;
				line-height: 1.3;
				max-width: 600px;
				margin: 0 auto 2rem;
				font-family: Helvetica,Roboto,Arial,sans-serif;
				font-size: 0.9rem;
			}

			ul{
				margin: 3rem auto 0;
				max-width: 700px;
				padding: 0;
				line-height: 0;
				font-size: 0;
				text-align: center;

				li{
					display: inline-block;
					width: 14%;
					margin-bottom: 1rem;

					a{
						margin: 0 auto;
						transition: transform 0.18s ease-out;
					}

					&:nth-child(1){
						a{
							@include sprite($asset, 68px,  65px, -161px, -79px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, -79px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(2){
						a{
							@include sprite($asset, 68px,  65px, -161px, 1px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, 1px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(3){
						a{
							@include sprite($asset, 68px,  65px, -161px, -321px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, -321px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(4){
						a{
							@include sprite($asset, 68px,  65px, -161px, -159px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, -159px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(5){
						a{
							@include sprite($asset, 68px,  65px, -161px, -239px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, -239px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(6){
						a{
							@include sprite($asset, 68px,  65px, -161px, -400px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, -400px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(7){
						a{
							@include sprite($asset, 68px,  65px, -161px, -966px);

							&:hover{
								@include sprite($asset, 68px,  65px, -76px, -966px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(8){
						a{
							@include sprite($asset, 68px,  65px, -164px, -1050px);

							&:hover{
								@include sprite($asset, 68px,  65px, -74px, -1050px);
								transform: scale(1.09);
							}

						}
					}

					&:nth-child(9){
						a{
							@include sprite($asset, 68px,  65px, -160px, -641px);

							&:hover{
								@include sprite($asset, 68px,  65px, -72px, -642px);
								transform: scale(1.09);
							}

						}
					}
					&:nth-child(10){
						a{
							@include sprite($asset, 68px,  65px, -162px, -721px);

							&:hover{
								@include sprite($asset, 68px,  65px, -74px, -721px);
								transform: scale(1.09);
							}

						}
					}
					&:nth-child(11){
						a{
							@include sprite($asset, 68px,  65px, -162px, -800px);

							&:hover{
								@include sprite($asset, 68px,  65px, -74px , -800px);
								transform: scale(1.09);
							}

						}
					}
					&:nth-child(12){
						a{
							@include sprite($asset, 68px,  65px, -162px, -882px);

							&:hover{
								@include sprite($asset, 68px,  65px, -74px, -882px);
								transform: scale(1.09);
							}

						}
					}
					&:nth-child(13){
						a{
							@include sprite($asset, 68px,  65px, -162px, -481px);

							&:hover{
								@include sprite($asset, 68px,  65px, -74px, -481px);
								transform: scale(1.09);
							}

						}
					}
					&:nth-child(14){
						a{
							@include sprite($asset, 68px,  65px, -162px, -560px);

							&:hover{
								@include sprite($asset, 68px,  65px, -74px ,-560px);
								transform: scale(1.09);
							}

						}
					}


				}


			}
		}
	}

	.contentSlide{
		padding-left: 0px;

	}

	section.content-slide{

		height: calc(100% - 92px);
		padding: 1rem;
		padding-top: 3rem;
		padding-left: 3rem;
		padding-bottom: 0;
		max-width: 1024px;
		margin: 0 auto 2rem;

		h1{
			font-size: 1.2rem;
			font-weight: bold;
			margin: 0rem 0 0.7rem;

			a{
				color: inherit;

				&:hover{
					text-decoration: underline;
				}
			}

			strong{
				color: $body-second-color;
			}
		}

		.img-slide{
			text-align: center;
			position: relative;


			a {
				overflow: hidden;
			    display: block;
			    position: relative;
			}

			img{
				width: 100%;
			}


			.imgHand {
			    width: 50%;
			    position: absolute;
			    animation: mymove 5s infinite;
			}

			@keyframes mymove {
			    0%  {top: 80%; left:80%}
			    25% {top: 35%; left:50%}
			    35% {top: 40%; left:70%}
			    45% {top: 40%; left:50%}
			    55% {top: 40%; left:70%}
			    65% {top: 45%; left:50%}
			    100%{top: 80%; left:80%}
			}



			.imgHandKey1 {
				 width: 50%;
			    position: absolute;
			    animation: mymoveKey1 5s infinite;
			}

			@keyframes mymoveKey1 {
			    0%  {top: 80%; left:80%}
			    40% {top: 35%; left:46%}			   
			    100%{top: 80%; left:80%}
			}


			.imgHandKey2 {
				width: 50%;
			    position: absolute;
			    animation: mymoveKey2 5s infinite;
			}

			@keyframes mymoveKey2 {
			    0%  {top: 80%; left:80%}
			    40% {top: 36%; left:50%}			   
			    100%{top: 80%; left:80%}
			}

			.imgHandKey12 {
				 width: 50%;
			    position: absolute;
			    animation: mymoveKey12 5s infinite;
			}

			@keyframes mymoveKey12 {
			    0%  {top: 80%; left:80%}
			    20% {top: 35%; left:46%}
			    50%{top: 80%; left:80%}
			    80% {top: 36%; left:50%}			   
			    100%{top: 80%; left:80%}
			}			

			.imgHandKey123 {
				 width: 50%;
			    position: absolute;
			    animation: mymoveKey123 7s infinite;
			}

			@keyframes mymoveKey123 {
			    0%  {top: 80%; left:80%}
			    15% {top: 35%; left:46%}
			    30%{top: 80%; left:80%}
			    45% {top: 36%; left:50%}
			    60%{top: 80%; left:80%}
			    75% {top: 36%; left:54%}			   
			    100%{top: 80%; left:80%}
			}

			.imgHandKey4 {
				width: 50%;
			    position: absolute;
			    animation: mymoveKey4 5s infinite;
			}

			@keyframes mymoveKey4 {
			    0%  {top: 80%; left:80%}
			    40% {top: 41%; left:47%}			   
			    100%{top: 80%; left:80%}
			}

			.imgHandKey5{
				width: 50%;
			    position: absolute;
			    animation: mymoveKey5 5s infinite;
			}

			@keyframes mymoveKey5 {
			    0%  {top: 80%; left:80%}
			    40% {top: 41%; left:50%}			   
			    100%{top: 80%; left:80%}
			}


			.divBackgroundVideo {
				background-image: url(../img/slides/tuitbdb/phone.png);
			    background-position: center center;
			    background-repeat: no-repeat;
			    background-size: contain;
			    height: 685px;
			    width: 354px;
			    position: relative;
			}

			.video {
				position: absolute;
			    left: 50%;
			    top: 50%;
			    transform: translate(-50% , -50%);

			    &.videoImg {
				    width: 79% !important;
				}

			}

			.bullet{
				position: absolute;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				color: white;
				font-size: 2.5rem;
				font-weight: bold;
				animation: pulse 0.5s infinite;
				text-align: center;
			}
			.cuenta-gif{
				width: auto;
				position: absolute;
				height: 5.7%;
			}

			.hand-gif{
				width: auto;
				position: absolute;
				transform: rotate(20deg) scale(0.5);
				height: 40%;
			}

			.cursor-gif {
				width: auto;
				position: absolute;
				transform: rotate(20deg) scale(0.5);
				height: 25%;
			}



		}

		.toggle-menu{
			right: 1.5rem;
			top: 1.5rem;
		}

		.content-desc{

			margin-top: 50%;

			h2{
				font-size: 1.6rem;
				font-weight: bold;
				color: $font-hight-color;
				border-bottom: 1px solid;
			}

			p{
				a{
					display: inline-block;
					color: #46abd9;
				}

				span {
					color: #42B649;
				}

				font-weight: bold;
				font-size: 1.1rem;
				line-height: 1.2;
				min-height: 100px;

				&.subDescriptionTuitBdB {
					display: inline-block;
					margin-bottom: 0px;
				}

				&.subDescriptionTitleTuitBdB {
					color: #46abd9;
					margin-bottom: 0px;
				}


				&.subDescriptionTitle {
					color: #46b39c;
					margin-bottom: 0px;
				}

				&.subDescription {
					line-height: 1;
				    min-height: 0px;
				}

				&.description {
					min-height: 0px;
				}

			}


		}

		.content-buttons{
			text-align: center;
			margin: 2rem 0 0;

			&.buttons-left{
				text-align: left;

				.button{
					margin-left: 0;
				}

			}


		}

		&.slide-portrait{
			.content-desc{
				margin-top: 40%;

				h2{
					width: 200px;
				}


			}

			.img_full {
				text-align: center;
				width: 100% !important;
			}

			.img-slide{
				text-align: left;

				img:first-child{
					width: 95%;
				}
			}



		}

	}

	section.content-cover{

		position: absolute;
		width: 100%;
		min-height: 100%;
		right: 50%;
		bottom: 50%;
		transform: translate(50%, 50%);
		background: white;

		& > .row{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.columns{
				height: 100%;
				position: relative;
			}
		}

		.content-image{
			overflow: hidden;
			img{
				position: absolute;
				bottom: 50%;
				width: 100%;
				transform: translateY(50%);
			}
		}

		.menu-cover{

			.content-desc{
				width: 50%;

				.icon-cover{
					-webkit-animation-delay: 3s;
					animation-delay: 3.5s;
					animation: flipInX 1s;
				}



				.text-cover{
					animation: fadeInDown 0.5s;

					h2{
						font-size: 1.5rem;
						color: white;
						text-align: left;
						line-height: 1.1;
						margin-bottom: 1rem;
						padding-left: 8px;


					}

					ul{
						list-style: none;
						text-align: left;
						margin: 0;
						padding: 0;

						li{
							width: 100%;
							height: 50px;
							margin-bottom: 0.5rem;
							position: relative;

							a{
								position: absolute;
								width: 100%;
								height: 100%;
								font-weight: bold;
								text-align: left;
								padding-left: 1rem;
								display: table;
								margin: 0;

								span{
									display: table-cell;
									vertical-align: middle;
								}


							}


						}
					}
				}

				.button{
					animation: none;
				}
			}


		}

		.content-desc{
			position: absolute;
			right: 50%;
			bottom: 50%;
			transform: translate(50%, 50%);
			text-align: center;

			.icon-cover{
				animation: flipInY 1s;
			}

			.text-cover{
				margin: 1rem 0 7rem;
				animation: bounce 1s;

				h1{
					color: $black;
					font-weight: bold;
					line-height: 1;
					font-size: 3.7rem;
				}

				p{
					font-size: 1rem;
					color: $black;
					font-weight: bold;
					line-height: 1.3;
				}
			}

			.span-label{
				font-size: 1.5rem;
				margin-top: -3rem;
				display: block;
				animation: flipInY 1s;
			}

			.button{
				animation: flipInY 1s;
			}


		}
	}

	footer{
		position: absolute;
		bottom: 0;
		right: 0;


		&.footer-cover{
			background: #253771;
			width: 100%;
			text-align: right;
			height: 80px;

			img{
				height: 60px;
				position: absolute;
				right: 1.5rem;
				bottom: 50%;
				transform: translateY(50%);
			}


		}

		&.footer-home{
			bottom: 2rem;
			right: 50%;
			transform: translateX(50%);
		}

		&.footer-slide{
			position: static;
			padding: 0.5rem 1rem;
			text-align: right;
			background: #253771;
			height: 60px;
		}


	}
}

.buttonSlideVideo {
	width: 20% !important;
}

.button{
	width: 200px;
	background: white;
	border-color: $orange;
	color: $orange;
	font-weight: bold;
	border-radius: 5px;
	padding: 10px;
	margin: 0 0.5rem;
	font-size: 1rem;
	text-transform: uppercase;
	border: 1px solid $darkorange;



	&.small-button{
		padding: 9px;
		font-size: 0.9rem;
		min-width: 150px;
	}




}
.arrow{

	position: absolute;
	bottom: 50%;
	transform: translateY(50%);
	z-index: 100;
	cursor: pointer;

	&.arrow-left{
		@include sprite($asset, 60px, 113px, -605px, -861px);
		left: 1px;
	}
	&.arrow-right{
		@include sprite($asset, 60px, 113px, -673px, -861px);
		right: 0;
	}
}

.toggle-menu{
	width: 40px;
	height: 30px;
	position: absolute;
	right: 2rem;
	top: 2rem;
	cursor: pointer;

	span, &:before, &:after{
		content: "";
		display: block;
		height: 5px;
		width: 100%;
		background-color: rgba(18, 21, 45, 0.5);
		transition: all 0.28s ease-out;
		transform-origin: 50% 50%;
	}

	span{
		margin: 5px 0;
	}

	&:hover, &.active{
		span{
			opacity: 0;
		}

		&:before{
			transform: rotate(45deg) translate(11px, 11px);
		}

		&:after{
			transform: rotate(-45deg) translate(3px, -3px);
		}
	}
}

.home-button{
	@include sprite($asset, 87px, 87px, -7px, -611px);
	position: absolute;
	top: 1rem;
	left: 1rem;
	transition: transform 0.28s ease-out;

	&:hover{
		@include sprite($asset, 87px, 87px, -110px, -611px);
		transform: scale(0.95);
	}

}

.overlay-menu{
	opacity: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: $blue;
	z-index: -1;
	transition: all 0.28s ease-out;

	&.open-menu{
		opacity: 1;
		z-index: 1000;


		.main-menu{
			transform: translateX(0);			
		}

	}

	.main-menu{
		position: absolute;
		right: 0;
		width: 550px;
		height: 100%;
		background: white;
		transform: translateX(550px);
		transition: all 0.28s ease-out;
		transition-delay: 0.5s;
		overflow: auto;

		.toggle-menu{
			right: 1.5rem;
			top: 1.5rem;
		}

		ul{
			bottom: 50%;
			right: 50%;
			padding: 0;
			width: 70%;
			list-style: none;
			margin: 4rem auto;

			li{
				border-bottom: 1px solid rgba(93, 93, 93, 0.4);
				margin-bottom: 0px;
				position: relative;
				padding: 0.5rem 0;

				&:hover{
					color: $font-hight-color;

					&:before{
						content: "";
						display: block;
						position: absolute;
						width: 0;
						height: 0;
						border-top: 9px solid transparent;
						border-left: 12px solid $font-hight-color;
						border-bottom: 10px solid transparent;
						left: -26px;
						bottom: 58%;
						transform: translateY(50%);
					}

				}

				&:nth-child(1){
					.icon{
						@include sprite($asset, 55px, 57px, 0px, -929px);
					}

					&:hover{
						color: $internet-color;
						&:before{
							border-left-color: $internet-color;
						}
					}
				}
				&:nth-child(2){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -67px);
					}

					&:hover{
						color: $internet-color;
						&:before{
							border-left-color: $internet-color;
						}
					}
				}
				&:nth-child(3){
					.icon{
						@include sprite($asset, 53px, 53px, -5px, 0px);
					}

					&:hover{
						color: $bancamovil-color;
						&:before{
							border-left-color: $bancamovil-color;
						}
					}
				}
				&:nth-child(4){
					.icon{
						@include sprite($asset, 53px, 53px, -5px, -268px);
					}

					&:hover{
						color: $servilinea-color;
						&:before{
							border-left-color: $servilinea-color;
						}
					}
				}
				&:nth-child(5){
					.icon{
						@include sprite($asset, 53px, 53px, -5px, -134px);
					}

					&:hover{
						color: $cajeros-color;
						&:before{
							border-left-color: $cajeros-color;
						}
					}
				}
				&:nth-child(6){
					.icon{
						@include sprite($asset, 53px, 53px, -5px, -335px);
					}

					&:hover{
						color: $multifuncional-color;
						&:before{
							border-left-color: $multifuncional-color;
						}
					}
				}
				&:nth-child(7){
					.icon{
						@include sprite($asset, 53px, 53px, -5px, -201px);
					}

					&:hover{
						color: $kiosko-color;
						&:before{
							border-left-color: $kiosko-color;
						}
					}
				}
				&:nth-child(8){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -799px);
					}

					&:hover{
						color: $tuitbdb-color;
						&:before{
							border-left-color: $tuitbdb-color;
						}
					}
				}
				&:nth-child(9){
					.icon{
						@include sprite($asset, 53px, 53px, -1px, -864px);
					}

					&:hover{
						color: $corresponsales-color;
						&:before{
							border-left-color: $corresponsales-color;
						}
					}
				}
				&:nth-child(10){
					.icon{
						@include sprite($asset, 53px, 53px, -5px, -535px);
					}

					&:hover{
						color: $aval-color;
						&:before{
							border-left-color: $aval-color;
						}
					}
				}
				&:nth-child(11){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -602px);
					}

					&:hover{
						color: $aval-center;
						&:before{
							border-left-color: $aval-center;
						}
					}
				}
				&:nth-child(12){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -669px);
					}

					&:hover{
						color: $bancamovil-color;
						&:before{
							border-left-color: $bancamovil-color;
						}
					}
				}
				&:nth-child(13){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -735px);
					}

					&:hover{
						color: $bobdb-color;
						&:before{
							border-left-color: $bobdb-color;
						}
					}
				}
				&:nth-child(14){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -401px);
					}

					&:hover{
						color: $pse-color;
						&:before{
							border-left-color: $pse-color;
						}
					}
				}
				&:nth-child(15){
					.icon{
						@include sprite($asset, 53px, 53px, -4px, -468px);
					}

					&:hover{
						color: #00aaff;
						&:before{
							border-left-color: #00aaff;
						}
					}
				}
			}

			a{
				font-size: 1.3rem;
				color: inherit;
				font-weight: bold;
				width: 100%;
				display: block;

				&:after, &:before{
					content: "";
					clear: both;
					display: block;
				}
			}

			span{
				&.texto{
					float: left;
					margin-top: 10px;
				}
				&.icon{
					float: right;
				}
			}
		}
	}
}

//>Skin Canales
.multifuncional{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -411px, 2px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $multifuncional-color;

		.icon-cover{
			@include sprite($asset, 145px, 120px, -941px, -23px);
			margin: 0 auto;
		}
	}

	.button{
		&.hight{
			background: $multifuncional-color;
			color: white;

			&:hover{
				background: transparent;
				color: $multifuncional-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $multifuncional-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{

		color: $multifuncional-color;
	}

	.bullet{
		background-color: $multifuncional-color;
	}
}

.kiosko{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -238px, -621px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $kiosko-color;

		.icon-cover{
			@include sprite($asset, 100px, 125px, -787px, -630px);
			margin: 0 auto;
		}
	}

	.button{
		color: $kiosko-color;
		border-color: #4e053a;

		&.hight{
			background: $kiosko-color;
			color: white;

			&:hover{
				background: transparent;
				color: $kiosko-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $kiosko-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $kiosko-color;
	}

	.bullet{
		background-color: $kiosko-color;
	}
}

.cajero{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -238px, -411px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color:$cajeros-color;

		.icon-cover{
			@include sprite($asset, 166px, 166px, -766px, -378px);
			margin: 0 auto;
		}
	}

	.button{
		color:$cajeros-color;
		border-color: #d8b543;

		&.hight{
			background:$cajeros-color;
			color: white;

			&:hover{
				background: transparent;
				color:$cajeros-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color:$cajeros-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $cajeros-color;
	}

	.bullet{
		background-color: $cajeros-color;
	}
}

.internet{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -238px, -205px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $internet-color;

		.icon-cover{
			@include sprite($asset, 138px, 166px, -776px, -173px);
			margin: 0 auto;
		}
	}

	.button{
		color: $internet-color;
		border-color: #003e7c;

		&.hight{
			background: $internet-color;
			color: white;

			&:hover{
				background: transparent;
				color: $internet-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $internet-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $internet-color;
	}

	.bullet{
		background-color: $internet-color;
	}
}

.bancamovil{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -238px, 2px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $bancamovil-color;

		.icon-cover{
			@include sprite($asset, 94px, 166px, -796px, 2px);
			margin: 0 auto;
		}
	}

	.button{
		color: $bancamovil-color;
		border-color: #cb5345;

		&.hight{
			background: $bancamovil-color;
			color: white;

			&:hover{
				background: transparent;
				color: $bancamovil-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $bancamovil-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $bancamovil-color;
	}

	.bullet{
		background-color: $bancamovil-color;
	}
}

.servilinea{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -238px, -826px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $servilinea-color;

		.icon-cover{
			@include sprite($asset, 166px, 166px, -776px, -802px);
			margin: 0 auto;
		}
	}

	.button{
		color: $servilinea-color;
		border-color: #3fa18c;

		&.hight{
			background: $servilinea-color;
			color: white;

			&:hover{
				background: transparent;
				color: $servilinea-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $servilinea-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $servilinea-color;
	}

	.bullet{
		background-color: $servilinea-color;
	}
}

.tuitbdb{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -584px, -617px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $tuitbdb-color;

		.icon-cover{
			@include sprite($asset, 130px, 166px, -1100px, -622px);
			margin: 0 auto;
		}
	}

	.button{
		color: $tuitbdb-color;
		border-color: #3f9ac3;

		&.hight{
			background: $tuitbdb-color;
			color: white;

			&:hover{
				background: transparent;
				color: $tuitbdb-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $tuitbdb-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $tuitbdb-color;
	}

	.bullet{
		background-color: $tuitbdb-color;
	}
}

.corresponsales{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -584px, -401px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $corresponsales-color;

		.icon-cover{
			@include sprite($asset, 130px, 166px, -1141px, -392px);
			margin: 0 auto;
		}
	}

	.button{
		color: $corresponsales-color;
		//border-color: #3f9ac3;
		border-color: #257F3C;

		&.hight{
			background: $corresponsales-color;
			color: white;

			&:hover{
				background: transparent;
				color: $corresponsales-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $corresponsales-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $corresponsales-color;
	}

	.bullet{
		background-color: $corresponsales-color;
	}
}
.avalpay{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -413px, -620px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $aval-color;

		.icon-cover{
			@include sprite($asset, 99px, 166px, -961px, -622px);
			margin: 0 auto;
		}
	}

	.button{
		color: $aval-color;
		border-color: $aval-color;

		&.hight{
			background: $aval-color;
			color: white;

			&:hover{
				background: transparent;
				color: $aval-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $aval-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $aval-color;
	}

	.bullet{
		background-color: $aval-color;
	}
}
.avalcenter{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -409px, -827px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $aval-center;

		.icon-cover{
			@include sprite($asset, 146px, 150px, -944px, -846px);
			margin: 0 auto;
		}
	}

	.button{
		color: $aval-center;
		border-color: $aval-center;

		&.hight{
			background: $aval-center;
			color: white;

			&:hover{
				background: transparent;
				color: $aval-center;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $aval-center;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $aval-center;
	}

	.bullet{
		background-color: $aval-center;
	}
}
.bancamovilsms{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -582px, -2px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $bancamovilsms-color;

		.icon-cover{
			@include sprite($asset, 94px, 135px, -1147px, -27px);
			margin: 0 auto;
		}
	}

	.button{
		color: $bancamovilsms-color;
		border-color: $bancamovilsms-color;

		&.hight{
			background: $bancamovilsms-color;
			color: white;

			&:hover{
				background: transparent;
				color: $bancamovilsms-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $bancamovilsms-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $bancamovilsms-color;
	}

	.bullet{
		background-color: $bancamovilsms-color;
	}
}
.mibobdb{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -583px, -207px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $bobdb-color;

		.icon-cover{
			@include sprite($asset, 120px, 120px, -1135px, -240px);
			margin: 0 auto;
		}
	}

	.button{
		color: $bobdb-color;
		border-color: $bobdb-color;

		&.hight{
			background: $bobdb-color;
			color: white;

			&:hover{
				background: transparent;
				color: $bobdb-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $bobdb-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $bobdb-color;
	}

	.bullet{
		background-color: $bobdb-color;
	}
}
.pse{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -408px, -206px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $pse-color;

		.icon-cover{
			@include sprite($asset, 140px, 124px, -950px, -232px);
			margin: 0 auto;
		}
	}

	.button{
		color: $pse-color;
		border-color: $pse-color;

		&.hight{
			background: $pse-color;
			color: white;

			&:hover{
				background: transparent;
				color: $pse-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $pse-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $pse-color;
	}

	.bullet{
		background-color: $pse-color;
	}
}
.clavesegura{
	.icon-cover{
		@include sprite($asset, 166px, 166px, -412px, -411px);
		margin: 0 auto;
	}

	.menu-cover{
		background-color: $clavesegura-color;

		.icon-cover{
			@include sprite($asset, 94px, 109px, -967px, -450px);
			margin: 0 auto;
		}
	}

	.button{
		color: $clavesegura-color;
		border-color: $clavesegura-color;

		&.hight{
			background: $clavesegura-color;
			color: white;

			&:hover{
				background: transparent;
				color: $clavesegura-color;
			}
		}
		&:hover, &.active, &:focus{
			background-color: $clavesegura-color;
			color: white;
		}
	}

	&.content-slide .content-desc h2{
		color: $clavesegura-color;
	}

	.bullet{
		background-color: $clavesegura-color;
	}
}
.landscape_phone {
	width: auto !important;
}
.view {
	width: 100%;
	height: 100%;
	background: white;
}
.view.ng-enter,
.view.ng-leave {
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}
.view.ng-enter {
	-webkit-animation: fadeIn 0.5s;
	animation: fadeIn 0.5s;
}



.h2Corresponsal {
	font-size: 27px;
    color: #80c448;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 50px;
}

.contencDescFull {
    margin-top: 4% !important;
}

.divCenterFull{
	left:50%;
    top:45%;
    position:absolute;
    transform: translate(-50%, -50%);
    width: 50%;


    .buttonCorresponsales {
	    border-color: #257F3C;
	}
}

.migaCorresponsal {
	position: absolute;
    right: 110px;
    top: 29px;
}

.content-multiple{
	margin-bottom: 1rem;
}
