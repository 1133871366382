@font-face {
  font-family: 'DTLArgoST';
  src: url('../fonts/DTLArgoST-Bold/DTLArgoST-Bold.eot');
  src: url('../fonts/DTLArgoST-Bold/DTLArgoST-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DTLArgoST-Bold/DTLArgoST-Bold.woff2') format('woff2'),
    url('../fonts/DTLArgoST-Bold/DTLArgoST-Bold.woff') format('woff'),
    url('../fonts/DTLArgoST-Bold/DTLArgoST-Bold.ttf') format('truetype'),
    url('../fonts/DTLArgoST-Bold/DTLArgoST-Bold.svg#DTLArgoST-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DTLArgoT';
  src: url('../fonts/DTLArgoST/DTLArgoT.eot');
  src: url('../fonts/DTLArgoST/DTLArgoT.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DTLArgoST/DTLArgoT.woff2') format('woff2'),
    url('../fonts/DTLArgoST/DTLArgoT.woff') format('woff'),
    url('../fonts/DTLArgoST/DTLArgoT.ttf') format('truetype'),
    url('../fonts/DTLArgoST/DTLArgoT.svg#DTLArgoT') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-hight-face: 'Pacifico';
$font-main-face: 'DTLArgoT';

//ASSETS
$asset: url('../img/newicons.png');
$asset-mobil: url('../img/newicons_mov.png');

//COLORS
$orange: #ea7823;
$magenta: #73195a;
$yellow: #f0c94b;
$dark-blue: #00458a;
$light-blue: #46abd9;
$red: #e15c4d;
$green: #46b39c;
$light-green: #80c448;
$darkorange: #d36828;
$light-grey: #969696;
$grey: #5d5d5d;
$second-grey: #747577;
$darkgrey: #231f20;
$black: #231f20;
$lightgrey: #efefef;
$blue: rgba(12,47,73, 0.8);
$aval: #003e7c;
$aval-center: #e15c4d;
$bobdb: #f0c94b;
$pse: #46abd9;
$bancamovilsms: #46b39c;
$clavesegura: #80c448;

//
$internet-color: $dark-blue;
$bancamovil-color: $red;
$servilinea-color: $green;
$cajeros-color: $yellow;
$multifuncional-color: $orange;
$kiosko-color: $magenta;
$tuitbdb-color: $light-blue;
$corresponsales-color: $light-green;
$aval-color: $aval;
$avalcenter-color: $aval-center;
$bobdb-color: $bobdb;
$pse-color: $pse;
$bancamovilsms-color: $bancamovilsms;
$clavesegura-color: $clavesegura;

$body-main-color: $grey;
$body-second-color: $black;

$button-hover-background: $lightgrey;

$font-main-color: $grey;
$font-hight-color: $orange;
//$font-light-color: $lightblack;


//MEASURES
$max-wrapp-width: 1124px;
$header-height: 80px;
$footer-height: 70px;

$label-font-size: 13px;
$button-font-size: 12px;
$margin-bottom-column: 0.3rem;

//Time
$img-zoom: 7s;